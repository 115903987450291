'use strict';
import React, { useState, useEffect, memo } from 'react';

import './App.css';

export default memo((props) => {
  return (
    <div className="mod">
      <div className="flex-col group">
        <img
          className="divider"
          src={'images/img_0.png'}
        />
        <div className="flex-col header">
          <div className="flex-row block">
            <div className="flex-row container">
              <img
                className="icon-circle"
                src={'images/img_1.png'}
              />
              <div className="flex-row wrapper">
                <div className="empty" />
                <div className="flex-row group-1">
                  <div className="vertical-line" />
                  <span className="info">让大数据应用更简单</span>
                </div>
              </div>
              <span className="tag">产品</span>
              <span className="label">特性</span>
              <span className="word">案例&模板</span>
              <span className="download">下载</span>
              <span className="tag-1">关于我们</span>
              <span className="label-1">合作伙伴</span>
              <span className="word-1">联系我们</span>
              <div className="flex-row tag-wrapper">
                <span className="tag-2">立即试用</span>
              </div>
            </div>
          </div>
          <div className="flex-row block-1">
            <img
              className="horizontal-line"
              src={'images/img_2.png'}
            />
            <img
              className="large-icon"
              src={'images/img_3.png'}
            />
            <img
              className="horizontal-line-1"
              src={'images/img_4.png'}
            />
            <div className="flex-row container-1">
              <img
                className="banner"
                src={'images/img_5.png'}
              />
              <span className="summary">真正免费的企业级BI分析平台，让大数据应用更简单~</span>
              <div className="flex-row wrapper-1">
                <span className="download-1">下载免费版</span>
              </div>
            </div>
            <span className="summary-1">高性能分析型数据引擎 | 企业级报表 | 自助式分析 | 嵌入式BI</span>
            <img className="layer" src={'images/img_6.png'} />
            <span className="title">GrandV BI 全能力的新一代BI可视化分析平台</span>
          </div>
        </div>
        <span className="book">我们为什么会不一样？</span>
        <span className="article">
          GrandV
          BI 的目标就是提供一站式全能力的敏捷数据分析工具，覆盖数据连接获取、数据高速查询、数据指标设计和数据分析可视化开发，提供功能强大但低使用门槛的手段，助力数据分析师、业务人员、管理人员快速实现数据洞察。系统内置高性能分析型数据引擎，提供企业级报表功能和自助式数据可视化开发工具，覆盖数据抽取、数据集成和数据加工及数据可视化等场景，助力企业快速实现数据分析应用和嵌入式的数据分析功能集成。
          正因为产品目标如此，“让大数据应用更简单”的信念如此，所以GrandV BI才会如此与众不同。 GrandV
          BI全面的能力使数据分析和数据应用变得更简单，更易于企业进行数据洞察和数据挖掘。
        </span>
        <div className="flex-row body-item-0">
          <img
            className="floor-bg"
            src={'images/img_7.png'}
          />
          <span className="caption">GrandV BI全面的能力使数据分析和数据应用变得更简单</span>
          <div className="flex-row block-2">
            <span className="more">查看更多</span>
          </div>
        </div>
        <span className="title-1">一个平台，四个强大的解决方案</span>
        <div className="flex-row submain-item-0">
          <img className="cover" src={'images/img_8.png'} />
          <div className="flex-col block-3">
            <span className="caption-1">开源嵌入式BI</span>
            <span className="article-1">
              系统采用微前端技术封装，能以组件的方式被第三方系统无缝集成，并且提供全套封装开源代码，开发人员可以直接修改开源代码实现自己的数据分析应用APP或者WEB应用。
            </span>
            <div className="flex-row container-2">
              <span className="detail">查看详情</span>
            </div>
          </div>
        </div>
        <div className="flex-row row-item-0">
          <div className="flex-col block-4">
            <span className="title-2">企业级报表</span>
            <span className="article-2">
              系统提供类Excel模式的表格编辑定义和渲染功能，支持中国式复杂表格的设计、多表同步渲染，支持表表联动、图表联动，层级表格，交叉表格，比较表格，表格计算函数，表格指标定义，累积占比计算，功能强大但简单易用能满足企业不同场景下对表格显示的需求。
            </span>
            <div className="flex-row container-3">
              <span className="detail-1">查看详情</span>
            </div>
          </div>
          <img className="banner-1" src={'images/img_9.png'} />
        </div>
        <div className="flex-row submain-item-1">
          <img className="cover-1" src={'images/img_10.png'} />
          <div className="flex-col block-5">
            <span className="caption-2">高性能分析型引擎</span>
            <span className="article-3">
              系统即可以连接外部数据源，也能通过内置的分析型大数据分析引擎独立完成PB级别的数据分析和即席查询，系统创造性的结合HTAP技术设计，采用智能调度和数据预聚合技术实现，能广泛支持多种不同业务类型的大数据多维分析、业务结算后数据修改再分析场景。
            </span>
            <div className="flex-row container-4">
              <span className="detail-2">查看详情</span>
            </div>
          </div>
        </div>
        <div className="flex-row row-item-1">
          <div className="flex-col block-6">
            <span className="title-3">交互式控件</span>
            <span className="article-4">
              系统内置丰富的交互式控件，如日历控件、下拉多级多选控件、基于数据源填充的树形选择控件，能满足对数据进行不同角度的灵活过滤分析需求，使数据操作者能随心所欲的操控数据，精准洞察。
            </span>
            <div className="flex-row container-5">
              <span className="detail-3">查看详情</span>
            </div>
          </div>
          <img className="banner-2" src={'images/img_11.png'} />
        </div>
        <div className="flex-row body-item-1">
          <img
            className="long-bg"
            src={'images/img_12.png'}
          />
          <span className="caption-3">GrandV BI全面的能力使数据分析和数据应用变得更简单</span>
          <div className="flex-row block-7">
            <span className="more-1">查看更多</span>
          </div>
        </div>
        <div className="flex-row row-4">
          <div className="flex-col block-8">
            <span className="tag-3">250+数据源</span>
            <img
              className="item-long"
              src={'images/img_13.png'}
            />
          </div>
          <div className="flex-col block-9">
            <div className="flex-row container-6">
              <span className="label-2">智能连接器</span>
              <span className="meta">智能化数据仓库</span>
            </div>
            <div className="flex-row background-wrapper">
              <img
                className="background"
                src={'images/img_14.png'}
              />
              <img
                className="product-long"
                src={'images/img_15.png'}
              />
              <div className="flex-row layer-wrapper">
                <img
                  className="layer-1"
                  src={'images/img_16.png'}
                />
                <img
                  className="picture"
                  src={'images/img_17.png'}
                />
              </div>
            </div>
          </div>
          <div className="flex-col block-10">
            <img
              className="action-bg"
              src={'images/img_18.png'}
            />
            <img
              className="entry-pic"
              src={'images/img_19.png'}
            />
            <span className="subtitle">展示仪表盘&预警</span>
            <div className="flex-col container-7">
              <div className="flex-col wrapper-2">
                <div className="flex-row group-item-0">
                  <div className="flex-row entry-pic-wrapper-item-0">
                    <img
                      className="entry-pic-1"
                      src={'images/img_20.png'}
                    />
                  </div>
                  <div className="flex-row entry-pic-wrapper-item-1">
                    <img
                      className="entry-pic-2"
                      src={'images/img_21.png'}
                    />
                  </div>
                </div>
                <div className="flex-row group-item-1">
                  <div className="flex-row view-item-0">
                    <img
                      className="logo"
                      src={'images/img_22.png'}
                    />
                  </div>
                  <div className="flex-row view-item-1">
                    <img
                      className="logo-1"
                      src={'images/img_23.png'}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-row button-bg-wrapper">
                <img
                  className="button-bg"
                  src={'images/img_24.png'}
                />
              </div>
            </div>
          </div>
        </div>
        <span className="title-4">智能化商业只需要四步</span>
        <div className="flex-row main">
          <div className="flex-col block-item-0">
            <div className="flex-row container-8">
              <div className="flex-row icon-circle-wrapper">
                <img
                  className="icon-circle-1"
                  src={'images/img_25.png'}
                />
                <span className="num">1</span>
              </div>
            </div>
            <img
              className="image"
              src={'images/img_26.png'}
            />
            <span className="info-1">轻松的数据连接</span>
            <span className="summary-2">
              连接并集中数据来自内置系统中的 250 多个源 -在数据仓库中创建数据的真实来源。
            </span>
          </div>
          <div className="flex-col block-item-1">
            <div className="flex-row container-9">
              <div className="flex-row icon-circle-wrapper-1">
                <img
                  className="icon-circle-2"
                  src={'images/img_27.png'}
                />
                <span className="num-1">2</span>
              </div>
            </div>
            <img
              className="figure"
              src={'images/img_28.png'}
            />
            <span className="word-2">数据管理</span>
            <span className="summary-3">
              准备并自动化您的数据刷新。清洁，加入，合并，合并，聚合使用我们的 ETL 功能。
            </span>
          </div>
          <div className="flex-col block-item-2">
            <div className="flex-row container-10">
              <div className="flex-row icon-circle-wrapper-2">
                <img
                  className="icon-circle-3"
                  src={'images/img_29.png'}
                />
                <span className="num-2">3</span>
              </div>
            </div>
            <img
              className="bitmap"
              src={'images/img_30.png'}
            />
            <span className="tag-4">数据可视化</span>
            <span className="summary-4">可视化您的 KPI指标，交互式和自动化仪表盘，我们提供 70 + 可视化小部件。</span>
          </div>
          <div className="flex-col block-item-3">
            <div className="flex-row container-11">
              <div className="flex-row icon-minus-wrapper">
                <img
                  className="icon-minus"
                  src={'images/img_31.png'}
                />
                <span className="num-3">4</span>
              </div>
            </div>
            <img
              className="picture-1"
              src={'images/img_32.png'}
            />
            <span className="label-3">轻松协作</span>
            <span className="summary-5">实时共享和协作，动态仪表板通过链接、导出或电子邮件发送您的团队或客户。</span>
          </div>
        </div>
        <div className="flex-row row-5">
          <div className="flex-row block-11">
            <span className="benifit">定制分析体验</span>
            <span className="benifit-next"></span>
          </div>
          <span className="caption-4">专为你设计</span>
        </div>
        <div className="flex-row row-6">
          <div className="flex-col block-item-0-1">
            <div className="flex-row entry-pic-wrapper">
              <img
                className="entry-pic-3"
                src={'images/img_33.png'}
              />
            </div>
            <span className="meta-1">适用于大中型企业</span>
            <span className="organization">
              数百名技能各异的用户和无数不同的数据来源？利用我们的企业报告和分析平台，为您的所有用户提供强大且易于使用的分析，以便根据实时洞察做出基于事实的决策
            </span>
            <div className="flex-row container-12">
              <div className="flex-row subtitle-wrapper">
                <span className="subtitle-1">企业分析入门</span>
              </div>
              <div className="flex-row icon-chat-wrapper">
                <img
                  className="icon-chat"
                  src={'images/img_34.png'}
                />
              </div>
            </div>
          </div>
          <div className="flex-col block-item-1-1">
            <div className="flex-row entry-pic-wrapper-1">
              <img
                className="entry-pic-4"
                src={'images/img_35.png'}
              />
            </div>
            <span className="info-2">对于软件供应商</span>
            <span className="article-5">
              将世界一流的分析嵌入到您的产品中，实现您的报告和分析愿景。我们的产品构架、开放式API和灵活的许可结构旨在实现可扩展性，可确保您实现利润最大化，并更快地将您的应用推向市场
            </span>
            <div className="flex-row container-13">
              <div className="flex-row desc-wrapper">
                <span className="desc">开始使用嵌入式商务智能</span>
              </div>
              <div className="flex-row wrapper-3">
                <img
                  className="horizontal-line-2"
                  src={'images/img_36.png'}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex-col row-7">
          <img
            className="background-1"
            src={'images/img_37.png'}
          />
          <span className="title-5">合作伙伴</span>
          <span className="summary-6">专业的运维支持和售后服务团队，让服务质量充分得到保障</span>
        </div>
        <div className="flex-row footer">
          <div className="flex-col block-12">
            <div className="flex-col container-14">
              <div className="flex-row wrapper-4">
                <span className="meta-2">云潮数据科技</span>
                <div className="flex-col group-2">
                  <span className="subtitle-2">BI软件&数据分析</span>
                  <span className="word-3">自助式BI </span>
                  <span className="tag-5">数据报告</span>
                  <span className="info-3">数据可视化软件 </span>
                </div>
                <div className="flex-col group-3">
                  <span className="label-4">资料&社区 </span>
                  <span className="word-4">帮助中心 </span>
                  <span className="tag-6">用户论坛(BBS)</span>
                  <span className="label-5">知乎专区</span>
                </div>
                <div className="flex-col group-4">
                  <span className="word-5">关于我们</span>
                  <span className="tag-7">公司介绍</span>
                  <span className="meta-3">合作伙伴计划</span>
                </div>
              </div>
              <div className="flex-col wrapper-5">
                <div className="flex-row group-5">
                  <span className="subtitle-3">数据分析软件</span>
                  <span className="label-6">BI模板&资源</span>
                </div>
                <div className="flex-row group-6">
                  <span className="word-6">嵌入式分析</span>
                  <span className="tag-8">地图类模板 </span>
                </div>
                <div className="flex-row group-7">
                  <span className="info-4">什么是大数据分析？</span>
                  <span className="label-7">图表类模板</span>
                </div>
                <div className="flex-row group-8">
                  <span className="word-7">报表软件 </span>
                  <span className="meta-4">图表交互式模板 </span>
                </div>
              </div>
              <span className="tag-9">移动端BI软件</span>
            </div>
            <div className="flex-col container-15">
              <div className="horizontal-line-3" />
              <div className="flex-row wrapper-6">
                <div className="flex-row group-9">
                  <span className="detail-4">联系地址：湖南长沙高新区延龙路72号云海通讯2栋10层1031</span>
                </div>
                <span className="label-8">
                  <a href="https://beian.miit.gov.cn/" target="_blank">@Copyright 2021-2039 湖南云潮科技有限公司 湘 ICP 备2022018147号-1 </a>
                  </span>
              </div>
            </div>
          </div>
          <span className="word-8">联系我们</span>
          <span className="tag-10">周一至周五 08:30～20:30</span>
          <span className="caption-5">188 7408 7526</span>
          <div className="flex-row tag-wrapper-1">
            <span className="tag-11">在线咨询</span>
          </div>
        </div>
      </div>
    </div>
  );
});
